import React from 'react'; 
function Contact() {     
    return (              
<>    
<main>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.493903491468!2d-79.39057322372038!3d43.84559067109367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2b491a5793a7%3A0x7b427e9d4d249729!2s200%20West%20Beaver%20Creek%20Rd%2C%20Richmond%20Hill%2C%20ON%20L4B%201C7!5e0!3m2!1sen!2sca!4v1732412638617!5m2!1sen!2sca" width="100%" height="450px" title="All Prints location" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  <div class="mx-auto grid max-w-7xl py-6 sm:px-6 lg:px-8">    
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
      <div className="max-w-xl lg:max-w-lg">
      <h3 className="text-xl font-bold tracking-tight sm:text-4xl">Operation Location</h3> 
      <p className="mt-4 text-lg leading-8 text-gray">
                    Unit 14 - 200 West Beaver Creek Road<br />
                    Markham, Ontario<br />
                    (416) 220 - 2200
                  </p>
        <h3 className="text-xl font-bold tracking-tight sm:text-4xl">Operation Hours</h3>
                  <p className="mt-4 text-lg leading-8 text-gray">
                    Monday - Friday 10AM - 5PM<br />
                    Appointments, Scheduled Pickups and Rush Services available.
                  </p>
                  <div className="mt-6 flex max-w-md gap-x-4">
                  </div>
                </div>
                <div className="max-w-xl lg:max-w-lg">
                  <h3 className="text-xl font-bold tracking-tight sm:text-4xl">Contact Us</h3>
                  <p className="mt-4 text-lg leading-8 text-gray">
                    Please use the following form to contact us with your inquiry.
                  </p>
                  <div className="mt-6 flex max-w-md gap-x-4">
                  <iframe title="InquiryForm" width="640px" height="480px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=XARwK5gm9UKSSKyj7IKDDS3YHONPdmdAgQo12GQbVKdUOERKSVlCVUdRNUVFNU9aRktRSDA0OTM2Ti4u&amp;embed=true" frameborder="0" marginwidth="0" marginheight="0"> </iframe>
                  </div>
                </div>    
      </div>
  </div>
</main></>
); 
} 
export default Contact;